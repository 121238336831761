.home-page .text-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  max-height: 90%;
}

.home-page h1 {
  color: var(--primary-colour);
  font-size: 56px;
  line-height: 53px;
  margin: 0;
  font-family: 'Coolvetica';
  font-weight: 400;
}

.home-page h1:before {
  font-family: 'La Belle Aurore', cursive;
  color: var(--primary-colour);
  font-size: 18px;
  position: absolute;
  margin-top: -40px;
  left: -15px;
  opacity: 0.6;
}

.home-page h1:after {
  font-family: 'La Belle Aurore', cursive;
  color: var(--primary-colour);
  font-size: 18px;
  position: absolute;
  margin-top: 18px;
  margin-left: 20px;
  animation: fadeIn 1s 1.7s backwards;
  opacity: 0.6;
}

.home-page h1 img {
  margin-left: 20px;
  opacity: 0;
  width: 32px;
  height: auto;
  animation: rotateIn 1s linear both;
  animation-delay: 1.4s;
}

.home-page h2 {
  color: var(--primary-colour);
  margin-top: 20px;
  font-weight: 400;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}

.home-page .flat-button {
  color: var(--primary-colour);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid var(--primary-colour);
  margin-top: 25px;
  float: left;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
}

.home-page .flat-button:hover {
  background: var(--complementary-colour-two);
  font-weight: 600;
  color: var(--text-colour);
}

.job-titles {
  overflow: hidden;
  height: 6rem;
  margin-top: 1rem;
}

.titles-list {
  position: relative;
  animation: cycle ease 12s infinite;
  height: 100%;
}

.job-title {
  display: block;
  height: 100%;
}

@keyframes cycle {
  0% {top: 0}
  10% {top: 0}
  20% {top: -6rem}
  30% {top: -6rem}
  40% {top: -12rem;}
  50% {top: -12rem;}
  60% {top: -18rem;}
  70% {top: -18rem;}
  80% {top: -24rem;}
  90% {top: -24rem;}
  100% {top: 0rem;}
}

.job-title:nth-child(1) {
  color:var(--primary-colour);
}
.job-title:nth-child(2) {
  color:var(--complementary-colour);
}
.job-title:nth-child(3) {
  color:var(--text-colour);
}
.job-title:nth-child(4) {
  color:var(--primary-colour);
}
.job-title:nth-child(5) {
  color:var(--complementary-colour);
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1:before,
  .home-page h1:after {
    display: none;
  }
  .home-page h1 {
    font-size: 4rem;
  }

  .job-titles {
    width: 44rem;
    /* display: flex;
    flex-direction: row; */
    
  }
/* 
  .job-title {
    display: flex;
    flex-direction: row;
  }

  .titles-list {
    display: flex;
    flex-direction: row;
  } */
  

  /* @keyframes cycle {
    0% { left: -1rem }
    100% { left: -204rem }
  } */

  .home-page .text-zone {
    position: initial;
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
  }

  .home-page .flat-button {
    float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }
}
