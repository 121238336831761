.contact-form {
  width: 100%;
  display: flex;
}

.contact-form ul {
  padding: 0;
  margin: 0;
}

.contact-form li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 10px;
  opacity: 0;
  overflow: hidden;
  display: block;
  clear: both;
  position: relative;
  animation: fadeInUp 2s 2s;
  animation-fill-mode: forwards;
}

.contact-form li.half {
  width: 49%;
  margin-left: 2%;
  float: left;
  clear: none;
}

.contact-form li.half:first-child {
  margin-left: 0;
}

.contact-form input[type='text'],
.contact-form input[type='email'] {
  width: 100%;
  border: 0;
  background: var(--complementary-colour-two);
  height: 50px;
  font-size: 16px;
  color: var(--text-colour);
  padding: 0 20px;
  box-sizing: border-box;
}

.contact-form input[type='text']:focus,
.contact-form input[type='email']:focus,
.contact-form textarea:focus {
  border: 1px solid var(--text-colour);
  outline: none;
}

.contact-form textarea {
  width: 100%;
  border: 0;
  background: var(--complementary-colour-two);
  height: 50px;
  font-size: 16px;
  color: var(--text-colour);
  padding: 20px;
  box-sizing: border-box;
  min-height: 150px;
}

.contact-form .flat-button {
  color: var(--text-colour);
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid var(--complementary-colour);
  float: left;
  border-radius: 4px;
  background: 0 0;
  text-transform: uppercase;
  float: right;
  text-align: center;
  margin-right: 10px;
}

.contact-name::before{
  content: '<const name = Harold Sadca />';
  animation: fadeIn 1s 1.7s backwards;
  color: var(--text-colour);
}

.contact-name::after{
  content: '<let email = hsadca@yahoo.com />';
  animation: fadeIn 1s 1.7s backwards;
  color: var(--text-colour);
}

.flat-button:hover {
  color: var(--tertiary-colour);
  background-color: var(--complementary-colour-two);
  border: 1px solid var(--tertiary-colour);
}

.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
}

.cubespinner div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0px lightyellow;
}

.cubespinner .face1 {
  transform: translateZ(100px);
  color: #dd0031;
}

.cubespinner .face2 {
  transform: rotateY(90deg) translateZ(100px);
  color: #f06529;
}

.cubespinner .face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  color: #28a4d9;
}

.cubespinner .face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  color: #5ed4f4;
}

.cubespinner .face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  color: #efd81d;
}

.cubespinner .face6 {
  transform: rotateX(-90deg) translateZ(100px);
  color: #ec4d28;
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    display: none;
  }
}