.project-card-container {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 600px;
  margin-top: 2rem;
  align-items: center;
  border-top: 3px solid var(--complementary-colour);
  border-bottom: 3px solid var(--complementary-colour);
}

.tech-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.title {
  font-size: xx-large;
}

.tech {
  border: 1px solid var(--complementary-colour);
  font-size: 1.5rem;
  padding: 1rem;
  margin: .5rem;
  border-radius: 5px;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  :hover {
    background-color: var(--tertiary-colour);
    border: none;
    color: var(--complementary-colour);
  }
}

.link {
  text-decoration: none;
  border: 1px solid var(--complementary-colour);
  font-size: 1.5rem;
  padding: 1rem;
  margin: .5rem;
  border-radius: 5px;
  color: var(--text-colour);
}

@media screen and (max-width: 1200px) {
  .project-card-container {
    margin: auto;
    width: 80%;
    margin-top: 5rem;
    margin-top: none;
  }
  
  .tech-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}