/* Page Styles */
.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Top Tags Styles */
.top-tags {
  bottom: auto;
  top: 35px;
}

/* Tags Styles */
.tags {
  color: var(--text-colour);
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
}

/* Bottom Tag HTML Styles */
.bottom-tag-html {
  margin-left: -20px;
}

/* Container Styles */
.container {
  width: 100%;
  height: 90%;
  min-height: 566px;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  display: flex;
  justify-content: space-evenly;
}

/* Specific Page Container Styles */
/* Heading Styles */
h1 {
  font-size: 5rem;
  font-family: 'Coolvetica';
  color: var(--primary-colour);
  font-weight: 400;
  margin-top: 0;
  position: relative;
  margin-bottom: 40px;
  left: -10px;
}
h1:before {
  font-family: 'La Belle Aurore', cursive;
  color: var(--text-colour);
  font-size: 18px;
  position: absolute;
  margin-top: -10px;
  left: -10px;
  opacity: 0.6;
  line-height: 18px;
}
h1:after {
  font-family: 'La Belle Aurore', cursive;
  color: var(--text-colour);
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  left: -30px;
  bottom: -20px;
  margin-left: 20px;
  opacity: 0.6;
}

/* Paragraph Styles */
p {
  font-size: 15px;
  color: var(--text-colour);
  font-family: sans-serif;
  font-weight: 300;
  max-width: fit-content;
  animation: fadeIn 1s 1.8s backwards;
}
p:nth-of-type(1) {
  animation-delay: 1.1s;
}
p:nth-of-type(2) {
  animation-delay: 1.2s;
}
p:nth-of-type(3) {
  animation-delay: 1.3s;
}

/* Text Zone Styles */
.text-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 35%;
  vertical-align: middle;
  display: table-cell;
  max-height: 90%;
}

/* Text Animate Hover Styles */
.text-animate-hover:hover {
  color: var(--tertiary-colour);
}


/* Media Query for Max Width 1200px */
@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }
  .container {
    position: initial;
    height: 100%;
    min-height: auto;
    display: block;
    width: 90%;
  }

  .text-zone {
    position: initial;
    transform: none;
    width: 100%;
    display: block;
    padding: 20px;
    box-sizing: border-box;
  }

  .container.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    h1.page-title {
      margin-left: 20px;
      margin-top: 20px;
    }
    .image-box {
      height: 200px;
      max-width: calc(50% - 10px);
    }
  }
  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
  }
}
