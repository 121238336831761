.nav-bar {
  background: var(--complementary-colour);
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;
}

.nav-bar .mode-toggle {
  display: block;
  padding: 8px 0;
}

.nav-bar .mode-toggle img {
  display: block;
  margin: 8px auto;
  width: 50px;
  height: auto;
}

.nav-bar .mode-toggle img.sub-mode-toggle {
  width: 50px;
}

.mode-toggle {
  text-align: center;
  height: 210px;
  width: 100%;
}

.nav-bar nav {
  display: block;
  text-align: center;
  position: absolute;
  height: 210px;
  top: 50%;
  margin-top: -120px;
  width: 100%;
}

.nav-bar nav a, .mode-toggle a {
  font-size: 22px;
  color: var(--complementary-colour);
  display: block;
  line-height: 51px;
  height: 51px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.nav-bar nav a i {
  transition: all 0.3s ease-out;
}

.nav-bar nav a:hover {
  color: var(--tertiary-colour);
}

.nav-bar nav a:hover svg {
  opacity: 0;
}

.nav-bar nav a:hover:after {
  opacity: 1;
}

.nav-bar nav a:after {
  content: '';
  font-size: 9px;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.nav-bar nav a:first-child:after {
  content: 'HOME';
}

.nav-bar nav a.about-link:after {
  content: 'ABOUT';
}

.nav-bar nav a.contact-link:after {
  content: 'CONTACT';
}

.nav-bar nav a.projects-link:after {
  content: 'WORK';
}

.nav-bar nav a.active svg {
  color: var(--tertiary-colour);
}

.nav-bar ul {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: block;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;
}

.nav-bar ul li a {
  padding: 7px 0;
  display: block;
  font-size: 15px;
  line-height: 16px;
  color: #4d4d4e;
}

.nav-bar ul li a:hover .anchor-icon {
  color: var(--tertiary-colour);
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: sticky;
    height: auto;
    min-height: auto;
  }

  .nav-bar ul,
  .nav-bar nav,
  .mode-toggle a {
    display: none;
  }

  .nav-bar nav {
    width: 100%;
    top: 0;
    left: 0;
    background: var(--complementary-colour);
    margin: 0;
    height: 65px;
    z-index: 2;
    position: fixed;
  }
  .nav-bar{
    height: 65px;
  }

  nav.mobile-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-bar nav a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-bar nav a:after {
    opacity: 1;
    position: initial;
    width: auto;
    margin-left: 10px;
  }

  .nav-bar nav a svg {
    opacity: 1 !important;
  }

  .hamburger-icon,
  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    z-index: 3;
  }

  .hide {
    display: none;
  }
}
