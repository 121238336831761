.body {
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 0;
  padding-left: 500px;
  overflow: hidden;
}

.tag-cloud {
  cursor: pointer;
  margin-top: 100px;
  position: relative;
}

.tag-cloud__item {
  font-weight: 800;
  left: 50%;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transition: all 0.1s;
  will-change: transform, opacity, filter;
}

.tag-cloud__item:nth-child(even) {
  color: var(--text-colour);
}

.tag-cloud__item:nth-child(odd) {
  color: var(--complementary-colour);
}


@media screen and (max-width: 1200px) {
  .body {
    padding-left: 0px;
  }
}
