/* Variable */
:root {
  --background-colour: #ECECEC;
  --primary-colour: #004080; /*  background color for headers or sections call-to-action buttons or links text headings or titles  */
  --complementary-colour: #6CA6CD; /*  accent color for buttons, icons, or interactive elements hover effects or highlights backgrounds of specific sections or blocks   */
  --complementary-colour-two: #FFFFFF; /*  background color for content areas main content area  for text in sections with darker backgrounds  */
  --text-colour: #333333;
  --tertiary-colour: #FFD700; /* for eye-catching call-to-action buttons, links, or icons use sparingly to draw attention
   to specific elements like quotes or important information selected icons or decorative elements for visual interest */
}
@media (prefers-color-scheme: dark) {
  :root {
    --background-colour: #333333;
    --primary-colour: #6CA6CD;
    --complementary-colour: #FFA500;
    --complementary-colour-two: #444444;
    --text-colour: #FFFFFF;
    --tertiary-colour: #FFD700;
  }
}

/* Import statements */
@import 'animate.css';
@import "~animate.css/animate.css";

/* Font Faces */
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2'),
    url('./assets/fonts/LaBelleAurore.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2'),
    url('./assets/fonts/CoolveticaRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Input and Textarea Styles */
input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

/* Loader Styles */
.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}
