.profile-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile {
  width: 45%;
  padding-right: 15rem;
}


@media screen and (max-width: 1700px) {
  .profile {
    padding-right: 9rem;
  }
}
@media screen and (max-width: 1200px) {
  .profile {
    display: none;
  }
}
