html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  display: block;
}

canvas {
  display: block;
  vertical-align: bottom;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}


@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}