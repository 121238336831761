.projects {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  margin-left: 50%;
  justify-content: center;
  color: var(--primary-colour);
}

.project-showcase {
  overflow-y: hidden;
}

.projects-label {
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
}

.skills-label {
  border-bottom: 1px solid var(--complementary-colour);
}

.skills-container {
  margin-top: 5rem;
}

.infinite-scroll-loop-outer {
  position: relative;
}

.infinite-scroll-loop-outer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    var(--complementary-colour) 0%,
      rgba(141, 79, 150, 0) calc(15%),
      rgba(139, 72, 141, 0) calc(85%),
      var(--background-colour) 100%
  );
  pointer-events: none;
}

.infinite-scroll-loop-inner {
  overflow-y: scroll;
  scrollbar-width: none;
}

.infinite-scroll-loop-inner::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1200px) {
  .projects {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    align-items: center;
    height: 100%;
    width: 90%;
    margin: auto;
    border-left: 2px solid var(--complementary-colour);
    border-right: 2px solid var(--complementary-colour);
  }

  .skills-label {
    width: auto;
  }

  .projects-label {
    margin-right: 0px;
    flex-direction: row;
  }
  .skills-container {
    margin-top: 0;
  }
  .infinite-scroll-loop-outer::after {
    background: linear-gradient(
      #6B3FA0 0%,
        rgba(141, 79, 150, 0) calc(0%),
        rgba(139, 72, 141, 0) calc(15%),
        var(--primary-colour) 100%
    );
    pointer-events: none;
  }
}
